<template>
  <CContainer>
    <CTypo tstyle="title2" class="mb-4">{{ $t('invoices') }}</CTypo>
    <CTable
      v-if="!loading"
      :data="invoices"
      box
      mobile-cards
      mobile-breakpoint="767px"
      v-bind="{ ...$attrs }"
      paginate
      :total="pagination.total"
      :current-page="pagination.currentPage"
      :per-page="perPage"
      @page-change="onPageChange"
    >
      <o-table-column
        :label="$t('date')"
        v-slot="{ row }"
        sortable
        width="130"
        field="date"
      >
        {{ parseDate(row.date) }}
      </o-table-column>
      <o-table-column
        :label="$t('number')"
        v-slot="{ row }"
        width="130"
        field="number"
      >
        {{ row.number }}
      </o-table-column>
      <o-table-column
        :label="$t('sum')"
        v-slot="{ row }"
        width="130"
        field="total"
      >
        {{ $n(parsePrice(row.total), 'currency') }}
      </o-table-column>
      <o-table-column
        width="160"
        v-slot="{ row }"
      >
        <div class="text-right">
          <CLink @click="download(row.id)">{{ $t('downloadAsPdf') }}</CLink>
        </div>
      </o-table-column>
    </CTable>
  </CContainer>
</template>

<script>
import { mapActions } from 'vuex';
import { toDecimalPrice } from '@contimo/api/src/utils/integerPriceParser';
import platformInvoices from '@contimo/api/src/api/platformInvoices';
import { GET_PLATFORM_INVOICES } from '@/store/actionTypes';

export default {
  computed: {
    invoices() {
      return this.$store.state.invoices.invoices;
    },
    loading() {
      return this.$store.state.invoices.loading;
    },
    pagination() {
      return this.$store.state.invoices.pagination;
    },
    perPage() {
      return 20;
    },
  },

  created() {
    this.getPlatformInvoices({ page: 1, perPage: this.perPage });
  },

  methods: {
    ...mapActions([GET_PLATFORM_INVOICES]),
    parsePrice(price) {
      return toDecimalPrice(price);
    },
    parseDate(date) {
      return new Date(date).toLocaleDateString();
    },
    onPageChange(page) {
      this.getOrders({ page, perPage: this.perPage });
    },
    download(id) {
      platformInvoices.show(id).then(({ data }) => {
        window.open(data.url, '_blank').focus();
      });
    },
  },
};
</script>
