var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CContainer',[_c('CTypo',{staticClass:"mb-4",attrs:{"tstyle":"title2"}},[_vm._v(_vm._s(_vm.$t('invoices')))]),(!_vm.loading)?_c('CTable',_vm._b({attrs:{"data":_vm.invoices,"box":"","mobile-cards":"","mobile-breakpoint":"767px","paginate":"","total":_vm.pagination.total,"current-page":_vm.pagination.currentPage,"per-page":_vm.perPage},on:{"page-change":_vm.onPageChange}},'CTable',Object.assign({}, _vm.$attrs),false),[_c('o-table-column',{attrs:{"label":_vm.$t('date'),"sortable":"","width":"130","field":"date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.parseDate(row.date))+" ")]}}],null,false,2391112523)}),_c('o-table-column',{attrs:{"label":_vm.$t('number'),"width":"130","field":"number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.number)+" ")]}}],null,false,373380764)}),_c('o-table-column',{attrs:{"label":_vm.$t('sum'),"width":"130","field":"total"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$n(_vm.parsePrice(row.total), 'currency'))+" ")]}}],null,false,1464615908)}),_c('o-table-column',{attrs:{"width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-right"},[_c('CLink',{on:{"click":function($event){return _vm.download(row.id)}}},[_vm._v(_vm._s(_vm.$t('downloadAsPdf')))])],1)]}}],null,false,4114420757)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }